<template>
  <section class="body">
    <section class="banner">
      <div class="wrap">
        <h2><strong>流动式智能健康服务车</strong>(01版)</h2>
        <p>智能化核酸采样、送样车，减少风险接触，提升防护</p>
        <img src="../../assets/v3/sampling/car_01.png" width="1268" height="564" class="img" >

        <div class="brag">
          <ul class="list">
            <li>
              <span class="icon">
                <img src="../../assets/v3/sampling/svg/icon_1.svg" width="28" height="28" />
              </span>
              <span class="txt">机动采样</span>
            </li>
            <li>
              <span class="icon">
                <img src="../../assets/v3/sampling/svg/icon_3.svg" width="28" height="28" />
              </span>
              <span class="txt">粒子消杀 <sup>＊</sup></span>
            </li>
            <li>
              <span class="icon">
                <img src="../../assets/v3/sampling/svg/icon_4.svg" width="28" height="28" />
              </span>
              <span class="txt">无接触采样</span>
            </li>
            <li>
              <span class="icon">
                <img src="../../assets/v3/sampling/svg/icon_5.svg" width="28" height="28" />
              </span>
              <span class="txt">SVaas方案</span>
            </li>
            <li>
              <span class="icon">
                <img src="../../assets/v3/sampling/svg/icon_7.svg" width="28" height="28" />
              </span>
              <span class="txt">UV消杀</span>
            </li>
          </ul>
          <p class="tip"><sup>＊</sup>中科醒诺高能粒子隧道消杀技术</p>
        </div>
      </div>
    </section>

    <section class="setting">
      <img class="img" src="../../assets/v3/sampling/setting_01.png" width="1260" height="788" />
      <div class="wrap">
        <h3>主要配置</h3>
        <ul class="list">
          <li>
            <img class="pic" src="../../assets/v3/sampling/pic_1.png" width="66" height="66" />
            <span class="txt">隔着玻璃窗采样实现医护与受检者保护屏障</span>
          </li>
          <li>
            <img class="pic" src="../../assets/v3/sampling/pic_2.png" width="66" height="66" />
            <span class="txt">车内配有专用冰箱样品恒温保存保证试管样本安全</span>
          </li>
          <li>
            <img class="pic" src="../../assets/v3/sampling/pic_3.png" width="66" height="66" />
            <span class="txt">高能粒子隧道消杀技术无毒无害，采样、消杀同步进行99.99%病毒消杀率</span>
          </li>
          <li>
            <img class="pic" src="../../assets/v3/sampling/pic_4.png" width="66" height="66" />
            <span class="txt">配备检测桌板和材料置物架</span>
          </li>
          <li>
            <img class="pic" src="../../assets/v3/sampling/pic_5.png" width="66" height="66" />
            <span class="txt">可调节高度的采样座椅</span>
          </li>
          <li>
            <img class="pic" src="../../assets/v3/sampling/pic_6.png" width="66" height="66" />
            <span class="txt">5G车联网</span>
          </li>
          <li>
            <img class="pic" src="../../assets/v3/sampling/pic_7.png" width="66" height="66" />
            <span class="txt">车载电脑</span>
          </li>
          <li>
            <img class="pic" src="../../assets/v3/sampling/pic_8.png" width="66" height="66" />
            <span class="txt">冷暖空调</span>
          </li>
          <li>
            <img class="pic" src="../../assets/v3/sampling/pic_9.png" width="66" height="66" />
            <span class="txt">扫码终端</span>
          </li>
          <li>
            <img class="pic" src="../../assets/v3/sampling/pic_10.png" width="66" height="66" />
            <span class="txt">AI摄像头</span>
          </li>
          <li>
            <img class="pic" src="../../assets/v3/sampling/pic_11.png" width="66" height="66" />
            <span class="txt">医废垃圾桶</span>
          </li>
        </ul>
      </div>
    </section>

  </section>
</template>

<script>
export default {
  name: "Sampling01",
};
</script>

<style lang="scss" scoped>
.banner {
  margin-bottom: 254px;
  font-size: 30px;
  color: #fff;
  line-height: 1.2;
  background-color: #E83E0B;

  .wrap {
    position: relative;
    top: 260px;
    display: flex;
    flex-direction: column;
    align-items: center;
  }

  h2 {
    margin-bottom: 10px;
    color: #fff;
    font-size: 80px;
    line-height: 1.2;

    strong {
      font-weight: bold;
    }
  }

  .img {
    position: relative;
    top: 68px;
    max-width: 100%;
    width: 1268px;
    height: auto;
  }

  .brag {
    position: relative;
    top: 32px;
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;
    font-size: 16px;
    line-height: 18px;
    color: #747A79;

    .list {
      margin-bottom: 28px;
      display: flex;
      color: #539B8A;

      li {
        margin: 0 20px;
        display: flex;
        flex-direction: column;
        align-items: center;

        .icon {
          margin-bottom: 12px;
          display: flex;
          align-items: center;
          justify-content: center;
          width: 60px;
          height: 60px;
          border-radius: 50%;
          background-color: rgba($color: #00A684, $alpha: 0.1);
        }
      }
    }

    sup {
      font-size: 12px;
    }
  }
}

.setting {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-end;
  padding: 12px 20px 28px;
  margin: 0 auto 120px;
  max-width: 1640px;
  min-height: 1080px;

  .img {
    position: relative;
    max-width: 100%;
    height: auto;
  }

  .wrap {
    margin-top: -320px;
    padding: 180px 40px 40px 100px;
    font-size: 18px;
    line-height: 22px;
    color: #fff;
    border-radius: 12px;
    background-color: #20C67F;

    h3 {
      margin-bottom: 40px;
      font-size: 24px;
      line-height: 34px;
      color: #fff;
    }

    .list {
      position: relative;
      display: flex;
      flex-wrap: wrap;
      justify-content: space-between;

      li {
        display: flex;
        padding-right: 60px;
        width: 25%;
        margin-bottom: 40px;

        &:nth-child(1), &:nth-child(2), &:nth-child(3) {
          width: 33%;
          .txt {
            max-width: 240px;
          }
        }

        .pic {
          margin-right: 18px;
          width: 72px;
          height: 72px;
          // overflow: hidden;
          // background-color: #fff;
        }

        .txt {
          max-width: 120px;
        }
      }
    }
  }
  
}


@media only screen and (max-width: 500px) {
  .banner {
    margin-bottom: 180px;
    font-size: 14px;
    line-height: 24px;
    
    .wrap {
      top: 160px;
      padding-bottom: 60px;
    }

    .logo {
      width: 195px;
      height: auto;
    }

    h2 {
      margin-bottom: 4px;
      font-size: 24px;
      line-height: 36px;
    }

    .img {
      top: 52px;
      width: 100%;
      height: auto;
    }

    .brag {
      top: 40px;
      padding: 0 4px;
      flex-wrap: wrap;
      font-size: 12px;
      line-height: 16px;

      .list {
        margin-bottom: 12px;

        li {
          margin: 0 8px 12px;

          .icon {
            margin-bottom: 8px;
            width: 36px;
            height: 36px;

            img {
              width: 20px;
              height: 20px;
            }
          }
        }
      }

      sup {
        font-size: 10px;
      }

      .tip {
        font-size: 10px;
        line-height: 14px;
      }
    }
  }

  .setting {
    padding: 0 12px;
    margin: 0 auto 40px;
    min-height: 640px;

    .wrap {
      margin-top: -92px;
      padding: 92px 24px 0;
      font-size: 14px;
      line-height: 24px;

      h3 {
        margin-bottom: 24px;
        font-size: 16px;
        line-height: 24px;
        color: #fff;
      }

      .list {
        li {
          padding-right: 12px;
          width: 50%;
          margin-bottom: 24px;

          &:nth-child(1), &:nth-child(2), &:nth-child(3) {
            width: 100%;
          }

          .pic {
            margin-right: 8px;
            width: 48px;
            height: 48px;
            // overflow: hidden;
            // background-color: #fff;
          }

          .txt {
            max-width: 120px;
          }
        }
      }
    }
  }
}
</style>